@import 'config';
@import 'global';
@media (min-width:280px) and (max-width:480px) {
    html {
        font-size: 64.5%;
        line-height: 90%;
    }
    body {
        width: 100%;
        padding: 0.5rem 1.0rem;
        font-size: 1.6rem;
        line-height: 1.6;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    article {
        width: 100%;
        box-sizing: border-box;
    }
    p {
        font-family: inherit;
        line-height: 2.0;
        letter-spacing: 0.10rem;
    }
    
    // h1, h2,h3,h4,h5,h6 {
    //     line-height: 1.5;
    // }
    // h1{
    //     font-size: 2.8rem;
    // }
    // h2 {
    //     font-size: 2.5rem;
    // }
    // h3 {
    //     font-size: 2.2rem;
    // }
    // h4 {
    //     font-size: 2.0rem;
    // }
    // h5 {
    //     font-size: 1.8rem;
    // }
    // h6 {
    //     font-size: 1.6rem;
    // }
    // a {
    //     &:link {
    //         color: #002157;
    //     }
    // }
    img {
        width: 100%;
        height: auto;
    }
    .bottom-section {
        margin: 1.0em 0em;
    }
    .banner-ad {
        box-sizing: border-box;
        padding: 1.5em;
        background-color: $bg-header-white;
    }
    .mobile {
        display: block;
        width: 100%;
        text-align: center;
    }
    header {
        display: block;
        background: none;
        clear: both;
        width: 100%;
    }
    #logo-header {
        display: none;
    }
    #contact-wrapp {
        position: relative;
        font-size: 0.9em;
        display: block;
        width: 94.80%;
        margin: 5px 10px;
        border-top: 1px solid #eee;
    }
    .line-strip-pink {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    #contact-us-header {
        position: relative;
        top: inherit;
        right: inherit;
        width: 100%;
        height: auto;
        min-height: 130px;
        padding: 4px 2px 4px 5px;
        color: #036;
        font: bold 18px $primary-fonts;
        text-shadow: 1px 1px 2px #666;
        img {
            display: none
        }
    }
    #sidebar-left {
        background: none;
        width: 100%;
        box-sizing: border-box;
        min-height: 0;
    }
    .contact-text {
        display: inline;
    }
    .img {
        &-header {
            display: none;
        }
        &-hover200 {
            a:hover {
                span {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    background-color: #ccc;
                    filter: alpha(opacity=50);
                    opacity: .5;
                    z-index: 2;
                }
            }
        }
    }
    #prod_search {
        width: 100%;
        box-sizing: border-box;
        z-index: 0;
        display: block;
        #search-input {
            width: 98%;
            box-sizing: border-box;
            height: 1.85em;
            border-radius: 1.5em;
            font-size: 1.1em;
        }
    }
    form#search {
        input {
            width: 98%;
            box-sizing: border-box;
            height: 1.85em;
            border-radius: 1.5em;
            font-size: 1.1em;
        }
         :last-child {
            display: none;
        }
    }
    .input-container {
        .bt-box {
            margin: 0.5rem 0;
        }
    }
    .banner-section,
    .addLineBt {
        display: none;
    }
    
    .block-container {
        background-color: $bg-header-white;
        padding: 1em;
    }
    .wrapper-box {
        box-shadow: none;
    }
    .middle-section-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 0.5em 1.0em;
        box-sizing: border-box;
        width: unset;
    }
    #weather {
        &-forecast {
            width: 100%;
            margin: 0.5em 0em;
            order: 2;
            border: 0px;
            background-color: $bg-header-white;
        }
    }
    #application {
        width: 100%;
        margin: 0.5em 0em;
        order: 1;
        border: 0px;
        background-color: $bg-header-white;
        >* {
            box-sizing: border-box;
        }
        >section {
            &.slick-slider {
                +div.block {
                    margin: 1em 0;
                    text-align: center;
                }
            }
        }
    }
    .feature {
        &-blog {
            .slick-list {
                width: 100%;
                height: 100%;
                max-height: 500px;
            }
            h5 {
                font-size: 1.8rem;
                margin: 1em auto;
                text-align: center;
            }
        }
        >.block {
            margin: 0.5em auto;
            text-align: center;
        }
    }
    div#article {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        float: none;
        position: unset;
    }
    #article {
        h2 {
            width: 100%;
        }
    }
    .bg-sidebar-white {
        border: 1px solid $bg-header-gray;
    }
    label {
        &.title {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
        }
    }
    #address-customer {
        margin: 0;
        padding: 0;
    }
    .coordinate-box {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        .box-wrapper {
            height: 50px;
            max-height: 50px;
        }
        img {
            width: auto;
            height: 100%;
        }
    }
    #feature {
        &-container {
            padding: 1.0em;
            background-color: #ededed;
        }
        &brand {
            width: 100%;
            box-sizing: border-box;
            position: relative;
        }
    }
    $grid:0,
    1,
    2,
    3,
    4,
    5;
    @each $grid in $grid {
        .LayoutGrid-#{$grid} {
            width: 100%;
            box-sizing: border-box;
            line-height: 1.5;
            margin: 0;
            padding: 2px;
        }
    }
    .LayoutGrid-5 {
        >.SiteFooterLinkList {
            display: flex;
            justify-content: space-around;
            align-items: start;
        }
    }
    #payment-footer {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        li.line-app {
            margin-left: 30%;
            text-align: center;
        }
    }
    #social-content {
        width: 100%;
        box-sizing: border-box;
        padding: 0em 3em;
        margin-left: 1.8em;
        li {
            background: none;
        }
    }
    .icon-nav {
        display: block;
        img {
            width: 100%;
            max-width: 32px;
            max-height: 32px
        }
        &-container {
            margin: 0px 2px 0 0;
            border: 1px solid #f06;
            margin: 2px;
            background-color: $bg-header-white;
            padding: 0.2em;
            max-width: 32px;
            max-height: 32px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    li {
        &.prod {
            font-size: 1.25em;
            display: block;
            width: 95.0%;
            margin: 16px 8px;
            padding: 0;
            border-radius: 2px;
            border: 1px solid $border-color;
            height: auto;
            background-color: $bg-header-white;
        }
        &-detail {
            padding: 1em;
        }
    }
    #title-content {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 2em 0;
        text-align: center;
        +section {
            background-color: $primary-bg;
        }
    }
    .paginate {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-size: 1.0em;
        margin-top: 1em;
        height: auto;
    }
    span {
        &.mouse,
        &.pumppingicon,
        &.concretestamp,
        &.wall {
            display: none;
        }
    }
    #breadcrumb {
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        padding: 1em 0;
        line-height: 1.5;
        border-radius: 0em;
        white-space: normal;
        height: auto;
        color: $color;
        background-image: none;
        a {
            font-size: 0.9em;
            margin: 0 2px;
            color: $a-link-color;
            text-decoration: none;
        }
    }
    .prod-detail {
        padding: 1em !important;
        ul {
            li {
                line-height: 1.2;
            }
        }
    }
    #pumppoon {
        article,
        .prodDetailSpec {
            width: 100%;
            box-sizing: border-box;
            height: auto;
        }
        &.prodDetailSpec {
            display: block;
            width: 100% !important;
            height: auto;
            padding: 4px 5px;
        }
        .top-section {
            width: 100% !important;
            height: auto;
            box-sizing: border-box;
            padding: 0.2em;
        }
        .middle-section,
        #search_tags,
        a.a-text-none {
            width: 100%;
            box-sizing: border-box;
            display: block;
        }
        h4 {
            +p {
                line-height: 1.5em;
            }
        }
    }
    .top-section {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        height: auto;
    }
    #pump-detail {
        .top-section {
            display: block;
            width: 100%;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            height: auto;
            border: none;
        }
        .middle-section {
            width: 100%;
            box-sizing: border-box;
            display: block;
            white-space: normal;
            font-size: 1.1em !important;
            font-family: $primary-fonts;
            // p {
            //     @extend .middle-section;
            // }
        }
        .bottom-section {
            p {
                font-size: 1.2em;
                line-height: 1.5;
                color: #333
            }
            h4 {
                display: block;
                width: 100%;
                box-sizing: border-box;
                margin-top: 5px;
                padding: 2px;
                border-bottom: 1px solid $border-color;
            }
        }
        #content {
            width: 100%;
            float: none;
            margin: 0;
            background-color: $primary-bg;
        }
        h4 {
            >span {
                display: flex;
                width: 100%;
                padding: 0px;
                margin: 0;
                justify-content: space-around;
                cursor: pointer;
                font-size: 0.8em;
                >span {
                    >span {
                        >span {
                            font-size: 0.8em;
                        }
                    }
                }
            }
        }
        span {
            &.salebutton {
                width: 100%;
                box-sizing: border-box;
                padding: 0px;
                background-image: none;
                line-height: 5px;
                cursor: pointer;
                a {
                    color: $color-lightest;
                    text-decoration: none;
                    >h5 {
                        font-size: 2.0em;
                        text-align: center;
                        line-height: 2.2em;
                    }
                }
                &:hover {
                    color: yellow;
                    background-color: $color;
                }
                +p {
                    margin: 2px 0;
                    padding: 2px;
                    a {
                        text-decoration: none;
                    }
                }
            }
            &pin {
                display: none;
            }
        }
        .price {
            +span {
                &.salebutton {
                    >a {
                        >h5 {
                            margin: 0 !important;
                            padding: 0;
                        }
                    }
                }
            }
        }
        #prodDetailWrapper {
            border: 0px solid $border-color;
            border-radius: 2px;
            margin: 16px 8px;
            padding: 2px 4px;
            background-color: $primary-bg;
            .top-section,
            .middle-section,
            .bottom-section {
                background-color: $bg-header-white;
                border: 1px solid $border-color;
                padding: 4px;
            }
            .top-section {
                flex-direction: column;
            }
        }
        #prodDetailImg {
            width: 100%;
            display: block;
            box-sizing: border-box;
            text-align: center;
        }
        .watermark-logo {
            display: none;
        }
        #title-content {
            +article {
                background-color: $primary-bg;
            }
        }
        #title-detail {
            +article {
                background-color: $primary-bg;
            }
        }
        .desc-pump {
            table {
                display: none;
            }
        }
        .prodDetailSpec {
            h3,
            p {
                text-align: center;
                font-size: 1.2em;
            }
        }
        a {
            &.a-text-none {
                width: 100%;
                display: block;
                color: yellow;
            }
        }
        #search_tags {
            width: 100%;
            display: block;
            box-sizing: border-box;
            ul {
                &.tags {
                    li {
                        a {
                            color: $color-lightest;
                            font-size: 1.2em;
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
        .product-buddy {
            width: 100%;
            box-sizing: border-box;
            display: block;
            min-height: 30em;
            &-img {
                img {
                    width: 100%;
                }
            }
            ul {
                li {
                    margin: 2em 1em;
                }
            }
        }
        button {
            &#desc-hide {
                width: 100%;
                box-sizing: border-box;
                display: block;
                font-family: $primary-fonts;
                cursor: pointer;
                padding: 4px;
                &::before,
                &::after {
                    content: "_";
                    font-size: 1.2em;
                    vertical-align: text-bottom;
                    margin: 0 4px;
                }
                &:hover {
                    background-color: #333;
                    color: $color-lightest;
                    font-size: 1.2em;
                }
            }
        }
    }
    .bg-black {
        background: $primary-bg;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQ1NDg0ZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: linear-gradient(to bottom, rgba(69, 72, 77, 1) 0%, rgba(0, 0, 0, 1) 100%);
    }
    #article-detail {
        #search_tags {
            width: 100%;
            display: block;
            box-sizing: border-box;
            ul {
                &.tags {
                    li {
                        a {
                            color: $color-lightest;
                            font-size: 1.2em;
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
    #search_tags {
        padding: 1em 2em;
        background-color: #ededed;
    }
    #pump-hire {
        #title-content {
            >h2 {
                font-size: 2em;
                line-height: 1.5em;
                width: 100%;
                box-sizing: border-box;
                display: flex;
            }
        }
        p {
            font-family: $primary-fonts;
            font-size: 1.1em;
            white-space: normal;
            line-height: 1.5em;
        }
        h4 {
            font-size: 1.2em;
            line-height: 1.5em;
        }
        .ContentTwoCollumn {
            width: 100%!important;
            box-sizing: border-box;
            display: block;
        }
        .similar {
            li {
                display: block;
                float: left;
                width: 100px;
                height: 180px;
                margin: 2px 1px 2px 1px;
                border: 1px dotted #f06;
                overflow: hidden;
                img {
                    width: 100%;
                    max-height: 80px;
                    max-width: 100px;
                }
            }
        }
    }
    .top-section {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        position: unset;
        font: 0.9em $primary-fonts;
        min-height: 0rem;
        height: auto;
        border: none;
        padding: 0rem;
        border-radius: 0px;
    }
    #product {
        .advertise,
        .plant_logo {
            display: none;
             :not(.advertise) {
                img {
                    width: 20%;
                    max-width: 150px;
                    max-height: 150px;
                }
            }
        }
        .img-hover240 {
            a:hover {
                span {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    min-height: 150px;
                    width: 100%;
                    background-color: #ccc;
                    filter: alpha(opacity=80);
                    opacity: .8;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
                    z-index: 2;
                    border-bottom: 4px solid $border-color;
                }
            }
        }
    }
    #title-content {
        display: block;
        width: 100%;
        height: auto;
        min-height: 20px;
        margin-top: 1.4rem;
        padding: 0.5rem;
        color: #036;
    }
    body#product-detail {
        #menuScrollInnerWrapper,
        .ribbon,
        .advertise,
        #similar-right,
        .advertise,
        #concrete_menufacturing,
        #breadcrumb,
        .product-avialability {
            display: none;
        }
        #menuScroll {
            margin-left: -9999px;
            display: none !important;
        }
        .logo {
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            height: auto;
            max-height: 80px;
            float: none;
            position: unset;
            margin: 0;
        }
        .row {
            #sidebar-left {
                width: 100%;
                box-sizing: border-box;
                padding-left: 0;
                float: none;
                background: none;
                height: auto;
                min-height: 0px;
            }
            >ul {
                &.tabs {
                    list-style: none;
                    width: 100%;
                    display: block;
                    box-sizing: border-box;
                    padding: 0 2px;
                    margin: 0.5em 0!important;
                    ;
                    background-color: $bg-header-white;
                    border: 1px solid $border-color;
                }
            }
        }
        #header-wrap {
            width: 100%;
            box-sizing: border-box;
            position: unset;
        }
        #title-content {
            >h2 {
                display: none;
            }
        }
        .prodDetailSpec {
            div.price {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                display: block;
            }
            >h3 {
                width: 100%;
                box-sizing: border-box;
                display: block;
                margin: 0rem;
                word-wrap: break-word;
                word-break: break-word;
                line-height: 1.33em;
                font-size: 1.25rem;
                color: $color;
            }
            .price-saver,
            .price-display {
                width: 100%;
                box-sizing: border-box;
                display: block;
                text-align: left;
                word-break: break-all;
                line-height: 1.5em;
                word-wrap: break-word;
            }
            >.item-content-wrapper {
                .price {
                    &-display {
                        >span {
                            font-size: 1.7rem;
                            color: $color-dark !important;
                        }
                    }
                    &-final {
                        >.vat_price {
                            font-size: 1.0rem;
                            color: $color-dark;
                        }
                    }
                }
            }
        }
        .item-content-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: block;
            padding: 0 .75em;
        }
        .require {
            .product-buy {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                display: block;
            }
            .product-qty {
                .calc-container {
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    border: 0px solid $border-color;
                    border-radius: 2px;
                    background-color: $bg-header-white;
                    padding: 0.33em;
                }
                >.row {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    margin: 0.1em;
                }
                >.calc-text {
                    width: 100%;
                }
            }
        }
        .product-buy,
        .product-product-addline,
        .product-quatation {
            width: 100%;
            padding: 0.75rem 1.0rem;
            a {
                font-size: 1.3em;
                text-decoration: none;
                color: $color-dark;
            }
            &:hover {
                color: $color-lighter;
                background-color: $border-color;
                border: 1px solid $border-color;
                cursor: pointer;
                a {
                    color: $color;
                }
            }
        }
        .price {
            span {
                &.discount {
                    &.discount-amount {
                        color: #fff;
                        background: red;
                        font-size: 1.1rem;
                        padding: 4px 6px;
                        float: none;
                        line-height: 1.8rem;
                        margin-top: 0;
                    }
                }
            }
        }
        .list-wrapper {
            ul.tags {
                margin: 0;
                padding: 0;
                position: unset;
                left: inherit;
                top: inherit;
                list-style: none;
                margin: 0 !important;
            }
        }
        header {
            background-color: $bg-header-white;
        }
        
        .watermark-logo,
        .sign-text {
            display: none;
        }
        .bottom-section {
            width: 100%;
            position: relative;
            background-color: $primary-bg;
        }
    }
    #product-detail {
        #content {
            width: 100%;
            display: block;
            box-sizing: border-box;
            margin: 0;
            float: none;
        }
        .prodDetailSpec {
            >h3 {
                width: 100%;
                box-sizing: border-box;
                display: block;
                margin: 0rem;
                word-wrap: break-word;
                word-break: break-word;
                line-height: 1.33em;
                font-size: 1.25rem;
                color: $color;
            }
        }
        #content {
            #title-content {
                border: none;
                border-radius: 0px;
                color: #036;
                position: relative;
                margin: 0;
                padding: 0;
                width: 100%;
                box-sizing: border-box;
                background: none;
            }
        }
    }
    body#content {
        article {
            div {
                #prodDetailWrapper {
                    width: 100%;
                    box-sizing: border-box;
                    display: block;
                    margin: 0;
                }
            }
        }
    }
    .prod {
        &DetailSpec {
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            position: relative;
            background-color: #ededed;
            float: none;
        }
        &DetailImg {
            width: 100%;
            box-sizing: border-box;
            display: block;
            background-color: #ededed;
        }
    }
    div.product-buy,
    div.price,
    div.product-qty,
    .compare-cart,
    .titleprod {
        border: 1px solid #ccc;
        width: 100%;
        padding: 0.5rem 1.0rem;
        margin-top: 1rem;
        background-color: var(--bg-header-white);
    }
    button.product-buy {
        cursor: pointer;
    }
    .price {
        span {
            &.discount {
                &.discount-percentage {
                    color: #fff;
                    background: red;
                    font-size: 1.1rem;
                    padding: 4px 6px;
                    float: none;
                    line-height: 1.8rem;
                    margin-top: 0;
                }
            }
        }
        &-final {
            line-height: 1.5;
            font-size: 1.5rem;
        }
        &-display {
            font-size: 2rem;
            margin: 1rem 0rem;
        }
    }
    html {
        >body#product-detail {
            background-color: #e5e5e5;
        }
    }
    .product-qty {
        width: 98%;
        .row {
            background: inherit;
        }
        .calc-select {
            select {
                color: $color;
            }
        }
        >form {
            >.row {
                position: relative;
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
    .bt-box button {
        box-shadow: none;
        color: inherit;
    }
    #header-wrap {
        >header {
            >.mobile {
                display: block;
            }
        }
    }
    .cart-box,
    .comparebtn,
    .bt-box,
    .product-compare {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    .spec {
        background-color: $bg-header-white;
        padding: 0.5rem;
        +.row {
            margin: 0;
        }
        >span {
            >h3 {
                font-size: 2.8rem;
            }
        }
    }
    .row button {
        font-family: $primary-fonts;
    }
    .btn-container,
    body#product-detail {
        .btn-container {
            >.bt-box {
                width: 100%;
                box-sizing: border-box;
                display: block;
                margin: 0;
                background-color: $bg-header-white;
                padding: 4px;
            }
        }
    }
    .cart {
        &-box {
            display: block;
            width: 100%;
            box-sizing: border-box;
            border-radius: 2px;
            color: $color;
            text-align: center;
            font-family: $primary-fonts;
            font-size: 16px;
            text-decoration: none;
            border: 1px solid $border-color;
            margin: 0.33em 0;
            font-weight: bold;
            cursor: pointer;
            background-color: $primary-bg;
            &:hover {
                background: rgba(250, 28, 95,0.6) transparent;
                cursor: pointer;
                text-decoration: none;
                color: #FFF;
                font-family: $primary-fonts;
            }
            a {
                text-decoration: none;
                color: $color;
                font-family: $primary-fonts;
                &:hover {
                    text-decoration: none;
                    color: $color-lightest;
                }
            }
        }
        &-hide {
            height: 0;
            overflow: hidden;
        }
    }
    .shopping-cart {
        &-box {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
            color: #FFD5D5;
            background: #FA1C5F;
            border-radius: 4px;
            padding: 10px;
            font: $primary-fonts;
            margin-top: 10px;
            display: none;
            z-index: 1;
            a {
                color: #FFD5D5;
                text-decoration: none;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -8px;
                width: 0;
                height: 0;
                border-bottom: 8px solid rgba(255, 0, 97, 1);
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
            ul {
                &.cart-products-loaded {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
            }
            .close-shopping-cart-box {
                float: right;
            }
            .remove-item {
                float: right;
                text-decoration: none;
            }
            .cart-products-total {
                font-weight: bold;
                text-align: right;
                padding: 5px 0px 0px 5px;
            }
            h3 {
                margin: 0;
                padding: 0px 0px 5px 0px;
            }
        }
        &-results {
            ul.cart-products-loaded {
                li {
                    background: #ED0C50;
                    margin-bottom: 1px;
                    padding: 6px 4px 6px 10px;
                }
            }
        }
    }
    .btn-container {
        width: 100%;
        box-sizing: border-box;
        display: block;
        .agreeterm {
            width: 100%;
            font-size: 1.5rem;
        }
        .agreeterm {
            input[type=checkbox] {
                width: 1.8em;
                height: 1.8em;
            }
        }
        button{
            width: 100%;
        }
    }
    .bt-box {
        >span {
            position: absolute;
            +input.bt {
                width: 100%;
                padding: 10px !important;
                text-align: center;
                cursor: pointer;
                font-family: $primary-fonts;
                font-size: 1.5em;
            }
        }
        button {
            width: 100%;
            text-shadow: none;
        }
    }
    .a-button-20{
        margin: 0.25em 0em;
    }
    .icon {
        padding: 10px;
        min-width: 50px;
        text-align: center;
        font-size: 1.5em;
    }
    .middle-section {
        width: 100%;
        box-sizing: border-box;
        margin: 5px auto;
        padding: 4px;
        border: none;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        border-radius: 0px;
        >.box-wrapper>p.bggre {
            background: $primary-bg;
            color: $color-dark;
            font-family: $primary-fonts;
            font-size: 1.2em;
            border: 1px solid $border-color;
            border-radius: 2px;
            word-break: break-all;
            word-wrap: break-word;
            white-space: normal;
        }
    }
    .agreeterm>label {
        margin: 4px 8px;
    }
    h4,
    h3 {
        >span.pin {
            display: none;
        }
    }
    .box-wrapper {
        display: block;
        width: 100%;
        box-sizing: border-box;
        >.spec {
            width: 100%;
            box-sizing: border-box;
            display: inline-block;
            padding: 4px;
            background-color: $bg-header-white;
            border-radius: 2px;
            .row_prodicon {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $border-color;
                border-radius: 2px;
                margin: 2px 0;
                padding: 0.15em;
                font-size: 1.5em;
                .prod_icon {
                    flex: 1;
                }
                h6 {
                    flex: 3;
                }
            }
        }
    }
    .desktop,
    .tablets {
        display: none;
    }
    ul.tabs li.desktop,
    ul.tabs li.tablets {
        display: none;
    }
    .poonmixlogo,
    .logo {
        width: 100px;
        display: block;
        height: auto;
        max-height: 104px;
        text-align: center;
        margin: 0 auto;
    }
    body#product-detail,
    body#article-detail {
        #search_tags {
            width: 100%;
            margin: 0;
            position: relative;
            border: 1px solid $border-color;
            background-color: $color-lightest;
            padding: 0.5em 0;
             :not(body#article-detail) {
                a {
                    color: $color-lightest;
                    font-size: 1.1em;
                }
                h6 {
                    margin: 0 1em;
                }
            }
        }
    }
    .product-buddy {
        position: relative;
        >.box-wrapper {
            position: unset;
            display: block;
            width: 100%;
            box-sizing: border-box;
            overflow: inherit;
            height: auto;
            min-height: 200px;
            background-color: #EEF5F9;
            margin: 0;
            padding: 5px;
        }
    }
    body#concrete-home {
        header {
            background-color: $bg-header-white;
        }
        #content {
            width: 100%;
            box-sizing: border-box;
            display: block;
            float: none;
            margin: 0;
            background-color: $bg-header-white;
        }
        .ContentTwoCollumn {
            width: 100% !important;
            box-sizing: border-box;
        }
        #prodDetailWrapper {
            width: 100%;
            display: block;
            box-sizing: border-box;
            border: 0;
            border-radius: 0px;
            margin: 0;
            padding: 0;
            background-color: $bg-header-white;
        }
        .article-img-detail {
            display: none;
        }
        #title-content {
            display: block;
            width: 100%;
            box-sizing: border-box;
            margin: 2em 0;
            text-align: center;
            background: $bg-header-white;
            border: 0px;
            >h2 {
                font-size: 1.5em;
                line-height: 1.6em;
                padding: 0;
            }
        }
        .feature-brand-container {
            width: 95.0%;
            margin: 16px 8px;
            padding: 0.1em;
            text-align: center;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            background-color: $primary-bg;
            border: 1px solid $border-color;
            h2 {
                background-color: $bg-header-white;
                border: 1px solid $border-color;
                padding: 0.5em;
                margin: 4px;
            }
            ul {
                width: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                list-style: none;
                margin: 0px;
                justify-content: space-around;
                text-indent: inherit;
            }
        }
        .TwoCollunmWidthSection {
            width: 100%;
            padding: 0;
            height: auto;
        }
        .top-section-wrapper {
            width: 100%;
            box-sizing: border-box;
        }
    }
    #contact-frm {
        form {
            padding: 4px;
            display: flex;
            flex-direction: column;
            input[type=text],input[type=email],textarea {
                width: 100%;
                box-sizing: border-box;
                padding: 4px 2px;
                line-height: 3em;
            }
        }
    }
    form>div,
    .btn-box>input {
        margin: 0.5em 0;
    }
    #prodDetailWrapper {
        border: none;
        background-color: $primary-bg;
        margin: 8px 16px;
        >.TwoCollunmWidthSection {
            background-color: $bg-header-white;
            border: 1px solid $border-color;
            >div ul {
                margin: 0 !important;
                padding: 0;
            }
        }
        .top-section-tags {
            background-color: $bg-header-white;
        }
    }
    #prodDetailWrapper>.TwoCollunmWidthSection>div,
    #prodDetailWrapper>.TwoCollunmWidthSection>div>h6,
    #prodDetailWrapper>.TwoCollunmWidthSection>div h3,
    #prodDetailWrapper>.TwoCollunmWidthSection>div h2,
    #prodDetailWrapper>.TwoCollunmWidthSection>div ul,
    #prodDetailWrapper>.TwoCollunmWidthSection>div ul li,
    #prodDetailWrapper>.TwoCollunmWidthSection>div ul li a {
        background-color: $bg-header-white;
    }
    .top-section {
        border: none;
        background-color: $primary-bg;
        &-wrapper {
            width: 100%;
            box-sizing: border-box;
            font-size: 1.1em;
        }
    }
    .bgwhite {
        background-color: $bg-header-white;
        border: 1px solid $border-color;
    }
    .icon-nav-article>a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    body#article-detail {
        #prodDetailWrapper {
            .spec {
                background-color: $bg-header-white;
                padding: 4px 6px;
            }
        }
    }
    .similar {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $border-color;
        padding: 2rem;
    }
    #faq {
        .similar {
            background-color: $primary-bg;
        }
    }
    .tags {
        &-list {
            flex: 1;
            .tags-desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .tags-desc {
                a {
                    &:hover {
                        color: #666;
                    }
                }
            }
        }
        &-img {
            flex: 1;
            img {
                width: 100%;
                height: auto;
            }
        }
        &-list {
            flex: 1;
            .tags-desc {
                img {
                    width: 16px;
                    height: 16px;
                }
                a {
                    &:hover {
                        color: #666;
                    }
                }
            }
        }
        &-desc {
            flex: 1;
            >* {
                flex: 1;
                >div {
                    width: 100% !important;
                }
            }
            .price {
                font-size: 3.0rem;
                font-weight: 900;
                color: red;
                color: var(--low-price-color);
            }
        }
    }
    body#tags-home {
        #sidebar-left {
            width: 100%;
            box-sizing: border-box;
            min-height: 0;
            margin: 10px auto;
        }
    }
    footer {
        .SiteFooter {
            li {
                line-height: 1.5;
            }
        }
        .SiteFooterLinkList {
            li {
                &.sitemap,
                &.career {
                    display: none;
                }
            }
        }
    }
    span.pin,
    #calculator {
        display: none;
    }
    body {
        background-color: $primary-bg;
    }
    .top-section {
        background-color: $primary-bg;
        .price {
            background-color: $bg-header-white;
            border: 1px solid $border-color;
            border-radius: 2px;
            padding: 4px;
        }
        h3 {
            &.titleprod {
                background-color: $bg-header-white;
                border: 1px solid $border-color;
                border-radius: 2px;
                padding: 4px;
            }
        }
        p {
            font-size: 1.1em;
            line-height: 1.2em;
        }
    }
    h3 {
        &.titleprod {
            font-size: 2.2em;
            line-height: 1.5;
        }
    }
    .ui-widget-content {
        li,
        p {
            font: 1.1em/1.3em $primary-fonts;
            letter-spacing: 0.05em;
        }
    }
    div.truck {
        display: none;
    }
    body#faq {
        .ui-accordion {
            .ui-accordion-header {
                a {
                    display: block;
                    font-size: 1em;
                    padding: .5em .5em .5em 2.0em;
                }
            }
        }
    }
    .similar {
        li {
            >div {
                flex: 1;
                padding: 0.5rem;
                min-height: 90px;
                overflow: hidden;
            }
        }
    }
    label.title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        background: $bg-header-white;
        margin: 0.5em 0;
        padding: 0.2em;
        >span {
            flex: 2;
        }
    }
    input,
    select,
    textarea {
        font-family: inherit;
        padding: 1em;
    }
    aside {
        &#right {
            background-color: $bg-header-gray;
            margin: 1em 1.5em;
        }
    }
    .line-strip-pink {
        background-color: #ee145b;
        height: 0.6875rem;
        width: 100%;
    }
    .tabs {
        background-color: $primary-bg;
        a {
            padding: 5px 10px;
            display: inline-block;
            color: $color-lightest;
            text-decoration: none;
            background: #1F5BAB;
            background-image: linear-gradient(to bottom, $bg-footer-1 0%, $bg-sidebar-gradient-1 100%);
            border-radius: 3px 3px 0px 0px;
            &:hover {
                background: #a7cce5;
                text-decoration: none;
                color: #036;
            }
            &.active {
                background: $bg-header-gray;
                color: $color-dark;
                border-width: 1px 1px 0px 1px;
                border-bottom-color: $bg-tab-1;
                border-bottom-style: solid;
            }
        }
        li {
            list-style: none;
            display: inline;
        }
    }
    li.active a,
    li.active a:hover {
        background: $bg-header-white;
        color: $color-dark;
        border-width: 0px 0px 1px 0px;
    }
    #tab {
        background-color: $bg-header-gray
    }
    #product-rating {
        >div {
            width: 100% !important;
        }
    }
    .r-tabs {
        .r-tabs-nav {
            display: none;
        }
        .r-tabs-accordion-title {
            display: block;
        }
    }
    section {
        &.how-to-contact-rent-pump {
            background-color: $bg-header-white;
            border: 1px solid $border-color;
            padding: 4px;
            margin: 0px 2px;
        }
        &.twocollumn-similar {
            >ul {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                >li {
                    position: relative;
                    width: 100%;
                    height: 100px;
                    margin: 4px 0;
                    display: flex;
                    .similar-img {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        &.nav-order-top {
            ul {
                &.step {
                    padding: 1em;
                    text-align: left;
                }
                &.step {
                    margin: 8px 16px;
                    background-color: $bg-header-white;
                    line-height: 1.5em;
                    border: 1px solid $border-color;
                }
            }
        }
        &.nav-order-bottom {
            >h4,
            >.help_box>ul {
                margin: 8px 16px;
                background-color: $bg-header-white;
                line-height: 1.5em;
                border: 1px solid $border-color;
            }
        }
    }
    form#pump-contact {
        padding: 4px;
    }
    a img {
        object-fit: cover;
    }
    .row_prodicon {
        >h6 {
            font-size: 1.6rem;
            line-height: 2.3em;
        }
        >h1 {
            margin: 8px 16px;
            background-color: $bg-header-white;
            line-height: 1.5em;
            border: 1px solid $border-color;
        }
    }
    #cart-content-container {
        width: 100%;
        box-sizing: border-box;
        background-color: $primary-bg;
        margin: 1em 0;
    }
    tr.hide,
    td.hide {
        display: none;
    }
    .hide,
    .hidden {
        display: none !important;
    }
    section {
        &.nav-order-bottom {
            >.help_box {
                >ul {
                    padding: 0.5em;
                }
            }
        }
        &.content form {
            margin: 8px 16px;
            background-color: $bg-header-white;
            line-height: 1.5em;
            border: 1px solid $border-color;
        }
    }
    .nav {
        &-container {
            background-color: $primary-bg;
            padding: 0.5em
        }
        &-forward-btn,
        &-backward-btn {
            background-color: $color;
            margin: 8px 16px;
            padding: 0.5em;
            text-align: center;
            a {
                background: none;
                border: none;
                box-shadow: none;
                border-radius: 2px;
                color: $color-lightest;
                text-shadow: none;
                margin: 2px;
                text-decoration: none;
                font-weight: normal;
            }
        }
    }
    #confirm-content {
        >div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            >label {
                flex: 1;
                text-align: right;
            }
            >input {
                flex: 2;
                margin: 0.5em 0em;
                font-family: $primary-fonts;
            }
            >textarea {
                flex: 2;
                margin: 1em 0em;
            }
            >select {
                flex: 2;
                margin: 0.5em 0em;
                padding: 0.5em;
                font-family: $primary-fonts;
            }
        }
    }
    .title {
        &-carier {
            display: none;
        }
        &_block {
            background-color: $primary-bg;
        }
    }
    .item-box {
        background-color: $bg-header-white;
        margin: 0.5em 0;
        padding: 0.5em;
    }
    .hilite {
        background-color: inherit;
    }
    #reg-id_gender1,
    #reg-id_gender2 {
        text-align: center;
    }
    .checkbox-container {
        text-align: center;
        background-color: $primary-bg;
        border: 1px solid #FFF;
    }
    .account_creation {
        margin: 8px 0px;
        background-color: $bg-header-white;
        padding: 0.25em 0.5em;
    }
    body#product {
        #content {
            .content-wrapper {
                margin: 8px 16px;
            }
        }
    }
    #googleMap,
    #map_canvas {
        width: 100%;
        height: 50em;
        box-sizing: border-box;
    }
    body#concrete_detail {
        main {
            width: 100%;
            box-sizing: border-box;
            margin-top: 6em;
            >section {
                margin: 8px 16px;
                padding: 5px 8px;
                background-color: $bg-header-white;
            }
        }
    }
    .block {
        display: block;
        width: 100%;
        height: auto;
        >div {
            width: 100%;
            box-sizing: border-box;
            margin-top: 2rem;
            padding: 0.5rem;
            background-color: $bg-header-white;
        }
    }
    .brand-info {
        >.block {
            display: flex;
        }
        >.brand-list {
            flex: 0 1 80px;
        }
    }
    #concretebylocation {
        .block {
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 4px;
        }
        >label,
        >select,
        >input {
            width: 100%;
            box-sizing: border-box;
        }
    }
    .list-img {
        img {
            width: 100%;
            height: auto;
        }
    }
    .modal-body {
        .tab-content {
            border: none;
            padding: 0;
            border-radius: 0px;
            background: none;
            box-shadow: none;
        }
    }
    #contactForm {
        input[type="text"],
        input[type="email"] {
            &:valid {
                color: green !important;
                background: none;
            }
        }
    }
    .map {
        margin: 4px 0px;
    }
    .article-img-detail {
        overflow: hidden;
    }
    .content-wrapper {
        overflow: hidden;
    }
    .nav-container {
        >.block {
            >.nav-forward-btn,
            .nav-backward-btn {
                box-sizing: border-box;
                width: 90%;
            }
        }
    }
    .tbl-textarea {
        overflow-x: auto;
    }
    .filter-container {
        margin: 1.2rem 1rem;
        padding: 0.5rem;
        background-color: #ededed;
        .filter-wrap {
            clear: both;
            max-height: 0px;
            transition: max-height 0.2s ease-out;
            overflow: hidden;
            margin: 1rem 0px;
        }
        #filter-menu {
            &:checked~ul {
                max-height: 500px;
            }
        }
        .show-menu {
            max-height: 10em;
        }
    }
    .btn-container {
        >button {
            width: 100%;
            padding: 0.5rem 1rem;
        }
        .mobile-inline {
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: none;
        }
        .calculator-icon {
            &::after {
                content: 'คำนวณ';
                font-size: 1.6rem;
            }
        }
    }
    .prodDetailSpec {
        >div {
            float: none;
        }
    }
    .eqiupment-similar {
        .box-wrapper {
            >ul {
                >li {
                    @include flexCenter;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    .product {
        &-buddy {
            .box-wrapper {
                >ul {
                    >li {
                        @include flexCenter;
                        width: 100%;
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                        margin: 2px 0;
                        background-color: #ddd;
                        padding: 2px;
                    }
                }
            }
        }
        &-img {
            width: 30%;
            +div {
                width: 70%;
                margin-left: 0.5rem;
            }
        }
        >a {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }
    input[type="checkbox"] {
        &.filter_selector {
            width: 30px;
            height: 30px;
            appearance: none;
            border: 1px solid $border-color;
            &:checked {
                background-color: $primary-bg;
            }
        }
    }
    .small {
        font-size: 1.2rem;
    }
    .bt-box {
        >button {
            padding: 1.5rem;
            border: 1px solid #ccc;
            font-size: 1.8rem;
        }
    }
    .list-offer {
        >div {
            >h2 {
                line-height: 3.5rem;
            }
        }
    }
    li {
        .list {
            background-color: $bg-header-white;
            margin: 2rem 0;
            flex: 1 0 auto;
        }
    }
    #product-rating {
        img {
            width: 16px;
            height: 16px;
        }
    }
    .box {
        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 4px 10px;
        }
        &-label {
            @include flexCenter;
            flex-wrap: nowrap;
            >img {
                width: 30px;
                height: 30px;
            }
        }
    }
    .require {
        >div {
            margin: 1rem 0.5rem;
        }
    }
    .forcast-img {
        img {
            width: 96px;
            height: 96px;
        }
    }
    @supports(display: grid) {
        body#pump-detail {
            #wrapper {
                header {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr;
                    grid-template-areas: "logo" "contact";
                    .logo {
                        grid-area: logo;
                    }
                    #contact-wrapp {
                        grid-area: contact;
                    }
                    #logo-header {
                        display: none;
                    }
                }
            }
        }
    }
    #cart {
        transition: height 0.3s ease-out;
    }
    body {
        height: 100%;
        margin: 0;
    }
    .show-cart {
        position: fixed;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-color: $primary-dark-bg;
        z-index: 2;
        &-info {
            position: fixed;
            @include flexCenter;
            flex-wrap: nowrap;
            width: 100%;
            height: auto;
            bottom: 0;
            left: 0;
            margin-bottom: 10em;
            padding-bottom: 3em;
            background-color: $primary-dark-bg;
            z-index: 1;
            color: $color-lightest;
        }
    }
    aside span.shopping-cart::before {
        font-size: 2em;
        color: $color-lightest;
    }
    #cart {
        &-info {
            >span {
                flex: 1;
                text-align: center;
            }
            >span {
                +* {
                    flex: 2;
                    margin: 0;
                    padding-top: 0.5em;
                }
            }
        }
        .btn {
            &-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                padding: 1em;
                background: none;
            }
            &-close {
                flex: 1;
                margin: 0em 0.2em;
                background-color: $primary-bg;
            }
            &-chkout {
                flex: 1;
                margin: 0em 0.2em;
                background-color: #333;
            }
        }
        .cart-item {
            display: none;
        }
        .close-window {
            display: none;
        }
    }
    .chkout {
        font-family: inherit;
        width: 100%;
    }
    .checkout {
        margin: 2rem 0rem;
    }
    .btn {
        &-purchase {
            padding: 1.0rem;
            background-color: #ccc;
            border-radius: 3px;
            font-family: inherit;
            width: 100%;
        }
        &-close,
        &-chkout {
            button {
                padding: 0.1em 2em;
                font-size: 1.5em;
                font-family: $primary-fonts;
                text-align: center;
                cursor: pointer;
                width: 100%;
            }
        }
        &-box{
            button{
                    width: 100%;
                }
            }
    }
    .schedule {
        margin-top: 5rem;
        >div {
            background-color: $bg-header-white;
            background-color: var(--bg-header-white);
            padding: 1rem;
        }
    }
    .delivery-channel {
        margin-top: 5rem;
        >div {
            background-color: $bg-header-white;
            background-color: var(--bg-header-white);
            padding: 1rem;
        }
    }
    .payment-channel {
        margin-top: 5rem;
        ul {
            background-color: $bg-header-white;
            background-color: var(--bg-header-white);
            padding: 1rem;
            list-style: none;
            >li {
                display: block;
                border: 1px solid #ccc;
                padding: 1rem;
            }
        }
    }
    .wrapper-cart {
        padding: 8px 16px;
        background-color: #ededed;
        background-color: var(--bg-header-grey);
    }
    .rentremark,
    .item {
        margin: 2rem 0rem;
        padding: 0.5rem;
        background-color: $bg-header-white;
        border: 1px solid $border-color;
    }
    #home_booking {
        >div {
            padding: 0.5em;
            background-color: $color-lighter;
            >.a-button-20 {
                +.a-button-20 {
                    margin-top: 2rem;
                }
            }
        }
        .btn-wrapper{
            padding: 0.5em;
            button{
                margin: 0.5em 0;
                padding: 0.5em 0;
                width: 100%;
            }
        }
    }
    // #compare {
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    //     min-height: 2px;
    //     max-height: 30em;
    //     background-color: #ddd;
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     grid-template-rows: auto;
    //     box-shadow: var(--box-shadow);
    //     h2 {
    //         grid-column: 1/-1;
    //         text-align: center;
    //         &::before {
    //             content: 'เปรียบเทียบ ';
    //         }
    //     }
    //     p {
    //         margin: 0;
    //     }
    //     .prod-desc {
    //         h4 {
    //             font-size: 0.9rem;
    //         }
    //     }
    //     .line-break,
    //     .compareTotal,
    //     a {
    //         grid-column: 1/-1;
    //     }
    //     .block {
    //         width: 100%;
    //         height: 100%;
    //         >.prod-desc {
    //             margin: 0;
    //             padding: 0 0.2em;
    //         }
    //     }
    //     .prod-img {
    //         margin: 0;
    //     }
    // }
    .quote_poon {
        width: 100%;
    }
    .calc {
        &-box {
            display: flex;
            justify-content: center;
            flex-direction: column;
            >.box-frm {
                flex: 1;
            }
            >.btn-box {
                width: 100%;
                >input {
                    width: 100%;
                    margin: 0.5em;
                }
            }
        }
        &-container {
            width: 100%;
            @include flexCenter;
            flex-direction: column;
            >div {
                flex: 1;
                margin: 1em 0em;
                width: 100%;
            }
        }
        &-input {
            input[type=text] {
                width: 100%;
                height: 100%;
                border: 1px solid $border-dark-color;
                border-radius: 0.133rem;
                font-family: inherit;
                margin: 0em;
                padding: 0.95em;
                text-align: center;
            }
        }
        &-select {
            >select {
                width: 100%;
                border: 3px solid $border-color;
                border-radius: 0.133rem;
                font-family: inherit;
                margin: 0;
                padding: 1em 0em;
                text-align: center;
                background-color: $bg-header-gray;
                color: $color-lightest;
            }
            &-text h3 {
                font-size: 1.5em;
            }
            &-container>.row>div {
                flex: 1 0 45%;
                background-color: $color;
            }
        }
    }
    .box-frm>input[type=text],
    .box-frm>select {
        width: 100%;
        padding: 0.5em 1em;
        margin: 1em 0;
    }
    form {
        >.box-frm {
            flex-direction: column;
            >* {
                flex: 1;
            }
        }
        >div {
            @include flexCenter;
            flex-direction: column;
        }
        .grid-input,.grid-unit{
            margin: 0;
            height: 100%;
            input{
                margin: 0;
            }
        }
    }
    .frm{
        input,select{
            width: 100%;
            padding: 0;
            margin: 4px 0;
        }
        span{
            width: 100%;
        }
    }
    .TextAlignLeft ul,
    .TextAlignLeft ol {
        margin: 0;
        text-indent: 0px;
        padding: 1em;
    }
    .navbar {
        display: none;
    }
    .footer {
        &-column {
            flex-wrap: wrap;
            >div {
                @include flexCenter;
                flex-wrap: wrap;
                flex-direction: column;
                >* {
                    flex: 1;
                }
            }
        }
        &-conc img {
            max-width: 50%;
            max-height: 100%;
        }
    }
    #credit-footer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .similar-text p {
        margin: 0;
        padding: 0;
    }
    .content-wrapper table {
        width: 100%;
    }
    span {
        &.cart-item-img {
            width: 50px;
            height: 50px;
            display: block;
        }
        &.remove {
            width: 24px;
            height: 24px;
            display: block;
            margin: 1rem;
        }
    }
    #home section.concrete {
        display: block;
    }
    #prodTop {
        display: grid;
        grid-template-columns: repeat(4, minmax(5em, 1fr));
        grid-template-areas: "img img img img""h1 h1 h1 h1""h2 h2 h2 h2" "h3 h3 h3 h3" "attribute attribute attribute attribute" "offer offer offer offer" "price price price price" "remark remark remark remark";
    }
    #menu {
        &-toggle {
            display: none;
        }
        &-container {
            border-bottom: 1px solid $border-color;
            margin-bottom: 10px;
        }
    }
    .LayoutCellSides>h4::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgb(248, 246, 246);
        float: right;
    }
    #concrete_type {
        width: 100%;
    }
}