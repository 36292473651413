@font-face {
    font-family: CS ChatThaiUI;
    src: url('../fonts/CSChatThaiUI.ttf'), url('../fonts/subset-CSChatThaiUI.eot?#iefix') format('embedded-opentype'), url('../fonts/subset-CSChatThaiUI.woff') format('woff'), url('../fonts/subset-CSChatThaiUI.svg#CSChatThaiUI') format('svg');
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?50450721');
    src: url('../fonts/fontello.eot?50450721#iefix') format('embedded-opentype'), url('../fonts/fontello.woff2?50450721') format('woff2'), url('../fonts/fontello.woff?50450721') format('woff'),  url('../fonts/fontello.svg?50450721#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    font-family: $primary-fonts;
}

html {
    // background: none repeat scroll 0 0 #F2F2F2;
    height: 100%;
    width: 100%;
    font-size: 62.5%;
}

.icon-poonmix-logo:before {
    content: '\e800';
}

.poonmix-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    font-size: 8em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px $border-dark-color;
}

@include minq(900px) {
    .containerWrapper {
        font-family: $primary-fonts;
        letter-spacing: 0.8px;
        background-color: #F9F9F9;
        font-size: 1.4rem;
        line-height: 1.5;
        color: $color;
        border-color: none;
        background: none repeat scroll 0 0 $bg-header-white;
        border-image: none;
        border-left: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
        border-style: none solid;
        border-width: medium 1px;
        box-shadow: 0 0 4px $box-shadow-color;
        margin: 3.2em auto 0 auto;
        min-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 70px;
        position: relative;
        z-index: 1;
        &:nth-child(n) {
            border: medium none;
        }
        &:before {
            clear: both;
            content: ".";
            display: block;
            height: 0;
            visibility: hidden;
        }
    }
}

.header-h1 {
    position: absolute;
    margin-left: -9999px;
}