@import 'config';
@import 'global';
@import 'utilities';


p {
    line-height: 2.0;
    letter-spacing: 0.5px;
    font-size: medium;
}
.watermark-logo {
    img {
        display: block;
        width: 32px;
        height: 32px;
        object-fit: contain;
    }
}
h1,h2,h3,h4,h5,h6{
    line-height: 1.5;
    letter-spacing: 0.5px;
}
h1 {
    font-size: 3.0em;
}
h2 {
    font-size: 2.0em;
}
h3 {
    font-size: 1.8em;
}
h4 {
    font-size: 1.6em;
}
h5 {
    font-size: 1.4em;
}
h6 {
    font-size: 1.2em;
}


@include minq(900px) {
    .navbar {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: $bg-header-white;
        display: flex;
        align-items: stretch;
        top: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        z-index: 30;
        &-begin {
            margin-left: 20px;
            font-family: 'Sailec-Regular', 'Helvetica', serif;
        }
        &-end {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            font-family: 'CS ChatthaiUI', serif;
            margin-right: 5em;
            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 25px;
                height: 40px;
            }
        }
        &-menu {
            letter-spacing: 0.18em;
            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 25px;
                height: 40px;
            }
        }
    }
    .bx {
        &-end {
            justify-content: flex-end;
            margin-left: auto;
            font-family: 'CS ChatthaiUI', serif;
            margin-right: 5em;
            display: flex;
        }
        &-e {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
    }
    .footer {
        &-conc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0em 3em;
        }
        &-conc {
            a {
                img {
                    width: 96px;
                    height: auto;
                }
            }
            >div {
                +div {
                    height: 40px;
                }
            }
        }
    }
    nav#menus {
        >ul {
            >li {
                >a {
                    color: $a-color;
                    &.closebtn{
                        position: absolute;
                        top: -55px;
                        right: -90%;
                        font-size: 36px;
                        margin-left: 50px;
                    }
                }
            }
        }
        ul {
            ul {
                .fas,
                .far {
                    color: rgb(210, 223, 223);
                }
            }
        }
    }
    .toggle,
    input+label.toggle {
        display: none;
    }
    span.slideToggle{
        font-size:30px;
        cursor:pointer;
        +#sidebar-left{
            height: 80%;
            // width: 0;
            position: absolute;
            background-image: linear-gradient(#add9e4,white, rgb(255, 255, 255));
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px; 
        }
        
    }
    .expandMenu{
        width: 80%;
    }
    .closeMenu{
        width: 0;
    }
    .bg-transparent-lg{
        background-color: transparent !important;
    }
}

@include maxq(899px) {
    
    nav.mains {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
        ul {
            @include flexCenter;
            flex-direction: column;
            list-style: none;
            padding: 1rem;
        }
        a {
            color: $color-lighter;
        }
    }
    label {
        &.drop-concrete {
            color: $color-lighter;
        }
        &.toggle {
            position: relative;
            padding: 30px 25px;
            cursor: pointer;
        }
    }
    nav.mains>ul>li>a {
        color: $a-color;
    }
    .nav {
        &-icon {
            background-color: #000;
            width: 30px;
            height: 5px;
            display: block;
            position: relative;
            transition: all 0.2s ease;
            &::after,
            &::before {
                position: absolute;
                content: "";
                background-color: #000;
                display: block;
                width: 30px;
                height: 5px;
                transition: all 0.2s ease;
            }
            &::before {
                top: 10px;
            }
            &::after {
                top: -10px;
            }
        }
    }
    #toggle {
        display: none;
        &:checked {
            +label.toggle {
                .nav-icon {
                    transform: rotate(135deg);
                    &::before,
                    &::after {
                        transform: rotate(90deg);
                        top: 0;
                    }
                }
            }
            ~nav.main,
            ~nav.mains {
                max-height: 100%;
            }
        }
    }
    [id^='drop'] {
        &:checked {
            ~label {
                transform: rotate(180deg);
            }
            ~ul {
                display: block;
                padding: 0;
            }
        }
    }
    span.slideToggle,a.closebtn{
        display: none;
    }
}


.bg-sidebar,
#sidebar-left {
    background-image: linear-gradient($bg-sidebar-gradient-1 , $bg-sidebar-gradient-2 );
}

footer {
    position: relative;
    text-align: center;
    // font-size: 0.875rem;
    clear: both;
}
.box_top_right,
.inputarea {
    border: 0 none;
    display: inline-block;
    font-size: 12px;
    height: 21px;
    margin-right: 5px;
    margin-top: 5px;
    overflow: hidden;
    padding: 0 5px;
    width: 140px;
    font-family: 'Microsoft Sans Serif', Tahoma, Geneva, sans-serif
}

.pagination {
        padding: 3px;
        margin: 3px;
        text-align: center;
        a {
            border: 1px solid #dedfde;
            margin-right: 3px;
            padding: 2px 6px;
            background-position: bottom;
            text-decoration: none;
            color: #0061de;
            &:hover {
                border: 1px solid #000;
                background-image: none;
                background-color: #0061de;
                color: #fff;
            }
        }
        span {
            &.current {
                margin-right: 3px;
                padding: 2px 6px;
                font-weight: bold;
                color: #ff0084;
            }
        }
        div {
            &.pagination {
                span {
                    &.disabled {
                        margin-right: 3px;
                        padding: 2px 6px;
                        color: #adaaad;
                    }
                }
            }
        }
    }


.tags {
    li,
    a {
        float: left;
        height: 24px;
        line-height: 24px;
        position: relative;
        font-size: 11px;
        margin-top: 4px;
    }
    a {
        margin-left: 20px;
        padding: 0 10px 0 12px;
        background: #0089e0;
        color: #fff;
        text-decoration: none;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        &:hover {
            background: #555;
            &::before {
                border-color: transparent #555 transparent transparent;
            }
        }
        &::before {
            content: "";
            float: left;
            position: absolute;
            top: 0;
            left: -12px;
            width: 0;
            height: 0;
            border-color: transparent #0089e0 transparent transparent;
            border-style: solid;
            border-width: 12px 12px 12px 0;
        }
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            float: left;
            width: 4px;
            height: 4px;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            border-radius: 2px;
            background: #fff;
            -moz-box-shadow: -1px -1px 2px #004977;
            -webkit-box-shadow: -1px -1px 2px #004977;
            box-shadow: -1px -1px 2px #004977;
        }
    }
}

.img-hover {
    a:hover {
        span {
            position: absolute;
            left: 0px;
            top: 0px;
            height: auto;
            min-height: 150px;
            width: 100%;
            background-color: #ccc;
            opacity: .5;
            z-index: 2;
        }
    }
}

.img-hover200 a:hover span {
    position: absolute;
    left: 0px;
    top: 0px;
    min-height: 225px;
    width: 100%;
    background-color: #ccc;
    opacity: .5;
    z-index: 2;
    height: auto;
}

.img-hover240 {
    a:hover {
        span {
            position: absolute;
            display: block;
            left: 0px;
            top: 0px;
            width: 100%;
            height: auto;
            min-height: 300px;
            background-color: #ccc;
            opacity: .8;
            z-index: 2;
            border-bottom: 4px solid #ededed;
        }
    }
}

.ui-widget-content {
    font: 13px/15px "Microsoft Sans Serif", Tahoma, Geneva, sans-serif;
}

.ui-widget-content {
    li,
    p {
        font-family: 'CS ChatthaiUI', "Microsoft Sans Serif", Tahoma, Geneva, sans-serif;
        letter-spacing: 0.4px;
        font-size: 1.2em;
        line-height: 1.8em;
    }
}

#siteFooter {
    background-image: linear-gradient($bg-footer-1 , $bg-footer-2);
}

.LayoutRowBottomAll {
    margin-top: 70px;
}

.LayoutGridBreakAfter {
    &::after {
        clear: both;
        content: " ";
        display: block;
        height: 0;
    }
}

.LayoutBreakAfter {
    &::after {
        clear: both;
        content: " ";
        display: block;
        height: 0;
    }
}

.LayoutCell,
.LayoutCellSides {
    padding: 9px 11px;
}

.LayoutGridBreak {
    clear: left;
}

.LinkInvert {
    a {
        &:link,
        &:visited {
            color: #CCCCCC;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }
        &:hover {
            color: #FFFFFF;
            text-decoration: underline;
        }
    }
}

.SiteHeaderDropPanelFirst {
    background: rgb(31, 91, 171);
    background: linear-gradient(to bottom, rgba(31, 91, 171, 1) 0%, rgba(17, 56, 122, 1) 100%);
    border-color: #1F5BAB #1F5BAC #11387A;
    border-image: none;
    border-style: none solid solid;
    border-width: 0 1px 1px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: #ccc;
    width: 30em;
}

.SiteHeaderDropPanelFirst {
    a:link {
        color: #CCCCCC;
    }
}

.SiteHeaderDropPanelFirst {
    a:visited {
        color: #FFFFFF;
    }
}

.SiteHeaderDropPanelSecond {
    border-color: none;
    @extend .SiteHeaderDropPanelFirst;
    box-shadow: 0 4px 10px $box-shadow;
    display: block;
    left: 0;
    line-height: normal;
    margin-left: -1px;
    margin-top: 0;
    padding: 6px 0;
    position: absolute;
    top: 100%;
    z-index: 1000;
    a:link {
        color: #CCCCCC !important;
    }
    a:visited {
        color: #FFFFFF !important;
    }
}

.nav_browser,
.img_nav_browser {
    position: absolute;
    right: 0px;
    bottom: -15px;
    margin-right: 1px;
    margin-bottom: 10px;
    overflow: visible;
}

.a-button-20 {
    box-shadow: inset 0px 1px 0px 0px #fbafe3;
    background: linear-gradient(to bottom, #ff5bb0 5%, #ef027d 100%);
    background-color: #ff5bb0;
    border-radius: 13px;
    border: 1px solid #ee1eb5;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: 'CS ChatthaiUI', 'Microsoft Sans Serif', Tahoma, Geneva, sans-serif;
    padding: 0.4rem 2.2rem;
    text-decoration: none;
    text-shadow: 0px 1px 0px 1px #c70067;
    &:hover {
        background: linear-gradient(to bottom, #ef027d 5%, #ff5bb0 100%);
        background-color: #ef027d;
    }
    &:active {
        position: relative;
        top: 1px;
    }
}

.tags {
    &-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        width: 100%;
        margin: 0;
        grid-gap: 1.0rem;
        >li {
            box-shadow: 1px 0px 0px rgba(104, 104, 104, 0.5);
            box-shadow: $box-shadow;
            padding: 0.5em;
            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        span {
            &.price {
                color: red;
                color: $low-price-color;
                font-size: 2.0rem;
            }
        }
        .tags-img {
            flex: 1;
            float: none;
        }
    }
    &-desc {
        flex: 2;
        padding: 0px 2px 0 2px;
        >div {
            >div {
                width: 100% !important;
            }
        }
    }
}

@include minq(900px) {
    .TextAlignLeft {
        text-align: left;
        list-style-position: inside;
        ul {
            margin: 5px 0 0 20px;
            text-indent: 28px;
            li {
                ul {
                    margin: 0 0 0 35px;
                    text-indent: 38px;
                }
            }
        }
        ol {
            margin: 5px 0 0 20px;
            text-indent: 28px;
            li {
                ol {
                    margin: 0 0 0 35px;
                    text-indent: 38px;
                }
            }
        }
    }
    .nav_browser {
        position: relative;
    }
}

.nav-container {
    display: block;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.nav-backward-btn a,
.nav-forward-btn a {
    line-height: 1.6em;
    padding: 6px 10px;
    border-radius: 1.5em;
}

.btn {
    &-reset,
    &-submit {
        font-size: 14px !important;
        padding: 20px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        border: none;
    }
    &-reset {
        background-color: #929292;
    }
}

.btn-submit {
    background-color: #004d9a;
}

.product-buy {
    >button {
        padding: 2em;
        font-size: 2rem;
        text-shadow: none;
    }
}

.price {
    &-list {
        text-decoration: line-through;
        font-size: 1.1rem;
    }
    &-final {
        font-size: 3.0rem;
    }
}

.line-break::before {
    content: '';
    display: block;
    width: 90%;
    height: 2px;
    background-color: #ccc;
    margin: 1.5rem auto;
}

.checkbox {
    input[type="checkbox"] {
        opacity: 0;
        +label {
            &::after {
                content: none;
            }
        }
        &:checked {
            +label::after {
                content: "";
            }
        }
    }
    &-del {
        input[type="checkbox"] {
            opacity: 0;
        }
        label {
            position: relative;
            display: inline-block;
            padding-left: 22px;
            &:hover {
                background-color: #DDD;
            }
            &::before {
                position: absolute;
                content: "";
                display: block;
                height: 16px;
                width: 16px;
                top: 3px;
                left: 0px;
                background-color: #000;
                border-radius: 50%;
            }
            &::after {
                position: absolute;
                content: "";
                height: 2px;
                width: 12px;
                top: 10px;
                background-color: #fff;
                transform: rotate(-45deg);
                left: 2px;
            }
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
            display: block;
        }
    }
    &:checked {
        +label {
            &::after {
                content: "";
            }
        }
    }
    label {
        position: relative;
        display: inline-block;
        padding-left: 22px;
        &::before,
        &::after {
            position: absolute;
            content: "";
            display: inline-block;
        }
        &::before {
            height: 16px;
            width: 16px;
            border: 1px solid;
            left: 0px;
            top: 3px;
        }
        &::after {
            height: 5px;
            width: 9px;
            border-left: 2px solid;
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            left: 4px;
            top: 7px;
        }
    }
    &-rental {
        >label {
            cursor: pointer;
        }
        >input {
            opacity: 0;
        }
    }
}

li.checkbox-del:hover {
    color: #333;
}

.bars {
    position: absolute;
    display: block;
    height: 2px;
    width: 12px;
    top: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 2px;
}

@include minq(900px) {
    #menu {
        &-toggle {
            display: none;
            +label.toggle {
                display: block;
                padding: 16px 6px;
                width: 30px;
                height: 8px;
                box-shadow: $box-shadow;
                margin: 0.4rem;
            }
            &:checked {
                +label {
                    >.menu {
                        &-icon-bar {
                            &::before,
                            &::after {
                                transform: rotate(135deg);
                                transform: rotate(90deg);
                                top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    +label {
                        >.menu-icon-bar::before {
                            transform: rotate(225deg);
                        }
                    }
                }
            }
        }
    }
    .menu {
        &-icon-bar {
            display: block;
            position: relative;
            width: 100%;
            height: 5px;
            background-color: #133d81;
            &::before,
            &::after {
                content: "";
                display: block;
                background-color: #133d81;
                position: absolute;
                width: 100%;
                height: 5px;
                transition: all 0.3s ease-out;
                top: 8px;
            }
            &::after {
                top: -8px;
            }
        }
    }
    #concrete_detail #menu-toggle:checked~aside>div,
    #article-detail #menu-toggle:checked~aside>div,
    #menu-toggle:checked~aside.nav-left>div {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        overflow: visible;
        transition: width 0.5s ease-out;
    }
    #concrete_detail #sidebar-left,
    #article-detail #sidebar-left,
    aside .nav-toggle {
        border-top: 5px solid #133d81;
        width: 0px;
        overflow: hidden;
        display: none;
    }
    #article-detail .top-section {
        padding-top: 0;
    }
    #article-detail #menu-toggle:checked~aside,
    #concrete_detail #menu-toggle:checked~aside,
    #menu-toggle:checked~aside.nav-left {
        position: absolute;
        z-index: 9999;
        width: 100%;
        opacity: 0.95;
    }
    .hideArrow {
        display: none;
    }
    @supports (display: grid) {
        .wrapper {
            display: grid;
            grid-template-columns: minmax(5px, max-content) 2fr;
            grid-template-rows: auto auto auto auto minmax(10em, max-content) 1fr auto;
            grid-area: "header header" "line-strip-pink line-strip-pink" "menu-tggle menu-toggle" "main main" "footer footer";
        }
        header,
        .line-strip-pink,
        .breadcrumb,
        .similar-area,
        .menu-toggle {
            grid-column: 1/-1;
        }
    }
    .preview {
        &-wrapper {
            display: block;
            width: 200px;
            height: 160px;
        }
        &-img {
            width: 100%;
            height: auto;
        }
    }
    .nav-img {
        &-container {
            margin: 0rem 1.0rem;
        }
        &-box {
            width: 32px;
            height: 32px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .nav {
        &-thumbnail,
        &-img-container {
            margin: 0px 2px 0 0;
            float: left;
            border: 1px solid #f06;
            margin: 2px;
            background-color: #fff;
            padding: 1px;
        }
    }
}

/*Main menu*/

@include minq(900px) {
    #concrete {
        .img-box {
            width: 30%;
        }
    }
    .img-box {
        >img {
            width: 100%;
            height: auto;
            box-sizing: border-box;
        }
    }
    .filter {
        label {
            flex: 1 0 40px;
            max-width: 50px;
            +* {
                flex: 0 1 90%;
                margin: 0.5rem;
            }
        }
    }
    nav {
        position: relative;
        z-index: 100;
        font-size: 14px;
        .nav-thumb-container {
            >.nav-thumbnail {
                width: 32px;
                height: 32px;
                img {
                    max-width: 32px;
                    max-height: 32px;
                }
            }
        }
        &.main {
            ul {
                a {
                    color: #ddd;
                }
            }
        }
        ul {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            &.st {
                position: relative;
                width: 300px;
            }
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                border-bottom: dotted 1px #0055A6;
                width: 100%;
                cursor: pointer;
                &:hover {
                    background-color: #1F5BAB;
                    position: relative;
                    ul {
                        left: 180px;
                        top: 0px;
                        display: block;
                        li {
                            ul {
                                position: absolute;
                                display: none;
                                li {
                                    width: 390px;
                                }
                            }
                            &:hover {
                                ul {
                                    display: block;
                                    left: 210px;
                                    top: 0;
                                    li {
                                        color: #333;
                                        width: 380px;
                                    }
                                    &:hover {
                                        background: #1F5BAB;
                                        width: 380px;
                                    }
                                    a {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }
                }
                a {
                    padding: 5px 15px;
                    color: #002157;
                    display: inline-block;
                    text-decoration: none;
                    font-size: 14px;
                    letter-spacing: 1.5px;
                    width: 100%;
                }
                ul {
                    position: absolute;
                    display: none;
                    &.st {
                        position: absolute;
                        display: none;
                    }
                    li {
                        a {
                            color: #454444;
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &.mobilemenu {
            ul {
                position: unset;
                width: unset;
                &.st {
                    position: unset;
                    width: unset;
                }
                li {
                    ul {
                        position: unset;
                        display: none;
                        &.st {
                            position: unset;
                            display: none;
                        }
                    }
                    &:hover {
                        ul {
                            li {
                                ul {
                                    position: unset;
                                    display: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .breadcrumb {
        width: 100%;
        line-height: 1.5;
        color: #FFF;
        background-color: #0055a6;
        padding: 0.25rem 0.5rem;
        min-height: 1.0rem;
        font-size: 1.5rem;
        letter-spacing: 1px;
        background-image: linear-gradient(to bottom, #0055A6 0%, #00A3EF 100%);
        a {
            &:hover {
                color: #CCC;
            }
            &:link {
                color: #fff;
            }
            &:visited {
                color: #fff;
            }
        }
        --bs-breadcrumb-divider: '\00BB'
    }
    .line-strip-pink {
        background-color: $line-strip-pink;
        background-color: $line-strip-pink;
        height: 10px;
        width: 100%;
        margin-bottom: 0.5em;
    }
   
    span.btn {
        cursor: pointer;
        display: block;
        transition: all .2s ease-in-out;
        &:hover {
            transform: scale(0.9);
        }
    }
    .btn {
        margin-top: 0.5em;
        border-bottom: 2px solid #e1e1e1;
        padding: 0.2em;
        &-container {
            button {
                width: 100%;
                padding: 0.2em;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
    }
    .footer-column {
        >.SiteFooterLinkList {
            @include flexSpace-Between;
        }
    }
   
    #breadcrumb {
        +main {
            grid-column: 1/-1;
            display: grid;
            grid-template-columns: repeat( 4, minmax(0px, max-content));
            gap: 0.5rem;
            &.content-area {
                grid-template-columns: 1fr;
            }
        }
    }
}


.showCart {
    transform: translateX(0);
}

.transparentBcg {
    visibility: visible;
}

@media all and (min-width:899px) {
    .btn {
        border: 1px solid transparent;
        padding: 1rem 2rem;
        font-size: 1em;
        line-height: 1.5;
        border-radius: 4px;
        user-select: none;
    }
    .cart-btn{position: relative;}
    .cart-items {
        position: absolute;
        top: -8px;
        right: 40px;
        font-size: 16px;
        color: #F06;
        background: #dedfde;
        padding: 4px 10px;
        border-radius: 45%;
    }
}

#paginations {
    >a {
        &.current {
            color: #F06;
            border: none;
            font-weight: 900;
        }
    }
}

.menu-bars {
    position: relative;
    z-index: 1;
}

.navigation {
    nav {
        background-image: linear-gradient(to bottom, $bg-sidebar-gradient-1, $bg-sidebar-gradient-2);
    }
}

.toggleProduct,
[id^=drop] {
    display: none;
}

@include maxq(768px) {
    nav {
        &.nav_product {
            position: relative;
            background-image: linear-gradient(to bottom, $bg-sidebar-gradient-1, $bg-sidebar-gradient-2);
            background-color: #fff;
            z-index: 1;
            ul {
                list-style: none;
                li {
                    display: block;
                    width: 100%;
                    margin: 2rem 0rem;
                    padding: 2rem 0.5rem;
                }
                ul {
                    display: none;
                }
            }
        }
        ul {
            ul {
                li {
                    display: block;
                    width: 100%;
                }
                ul {
                    li {
                        position: static;
                    }
                }
            }
        }
        .nav-img-box {
            display: block;
            width: 64px;
            height: 64px;
        }
    }
    .toggleProduct {
        display: block;
        float: right;
    }
    [id^=drop]:checked+ul,
    [id^=drop]:checked+ul>div>.flyout_left {
        display: block;
    }
    .SiteHeaderDropPanelFirst {
        width: 100%;
    }
    .nav_browser {
        width: 100%;
        position: relative;
        bottom: -30px;
    }
    .img_nav_browser {
        position: absolute;
        right: 0px;
        bottom: -15px;
        margin-right: 1px;
        margin-bottom: 10px;
        overflow: visible;
        z-index: -1;
    }
    .stwidth {
        position: relative;
    }
    ul.flyout_left,
    .st,
    .stwidth {
        width: 100%;
    }
}