$bg-sidebar-color: #edf3f4;
$bg-sidebar-color: #edf3f4;
$bg-sidebar-gradient-1: #add9e4;
$bg-sidebar-gradient-2: rgb(255, 255, 255); //#ffffff
$bg-header-1: #0054a6;
$bg-header-2: #003572;
$bg-header-white: #ffffff;
$bg-header-gray: #ededed; //#ededed
$bg-footer-1: #1f5bab;
$bg-footer-2: #11387a;
$bg-tab-1: rgb(221, 221, 221); //#ddd
$bg-tab-2: rgb(204, 204, 204); //#ccc
$primary-bg:rgb(238, 238, 238); //#eee
$h1-color: rgb(51, 51, 51); //#333
$color-dark: rgb(51, 51, 51); //#333
$color: #666;
$color-lightest:rgb(255, 255, 255);
$color-lighter:rgb(221, 221, 221); //#ddd
$price-color: #000;
$low-price-color: red;
$high-price-color: #ccc;
$a-color: rgb(51, 51, 51); //#333
$a-link-color:rgb(51, 51, 51); //#333
$a-hover-color: #ccc;
$box-shadow: 0 5px 10px rgba(104, 104, 104, 0.5);
$line-strip-pink: #ee145b;
$box-shadow-color:rgba(0, 0, 0, 0.5);
$box-shadow: 1px 1px 1px 2px rgba(220, 220, 220, 0.2);
$chatthaiFont:"CS ChatthaiUI",
Sans-serif,
Tahoma,
serif;
$primary-fonts:"CS ChatthaiUI",
Tahoma,
sans-serif,
Arial,
Helvetica;
$border-color:rgb(221, 221, 221); //#ddd
$border-dark-color:rgb(51, 51, 51); //#333
$primary-dark-bg:rgb(51, 51, 51); //#333
@mixin minq($screen) {
    @media only screen and (min-width: $screen) {
        @content;
    }
}

@mixin maxq($screen) {
    @media only screen and (max-width: $screen) {
        @content;
    }
}

@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexSpace-Between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@mixin flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}