@import 'config';
@import 'global';

@media (min-width:481px) and (max-width:899px) {
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    body {
        font: normal 1.4rem/1.6rem $primary-fonts;
        letter-spacing: 0.09em;
        background-color: #F9F9F9;
    }
    #wrapper {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        display: block;
    }
    p {
        font: 1.8rem/1.5 $primary-fonts;
        letter-spacing: 1.25px;
        margin: 1.0em;
        word-wrap: break-word;
    }
    .navbar {
        display: flex;
        justify-content: space-around;
        align-items: center;
        justify-content: end;
        padding: 0.75rem;
        &-begin {
            display: none;
        }
        &-end {
            a {
                &::after {
                    content: "|";
                    padding: 2px;
                }
                &:last-child {
                    &::after {
                        content: "";
                    }
                }
            }
        }
    }
    .line-strip-pink {
        background-color: $line-strip-pink;
        height: 10px;
        width: 100%;
        margin: 10px 0px;
        grid-column: 1/-1;
    }
    .menu {
        &-nav {
            ul {
                display: block;
                width: 50%;
                min-height: 30px;
                li {
                    border-bottom: 2px solid #ddd;
                    list-style: none;
                    margin-top: 5px;
                    padding-top: 2px;
                    a {
                        &:link {
                            color: white;
                            text-decoration: none;
                            cursor: pointer;
                        }
                        &:hover {
                            color: #eee;
                        }
                    }
                }
            }
            a {
                &:link {
                    text-decoration: none;
                    color: #333;
                }
            }
        }
        &-text {
            position: relative;
            display: block;
            width: 50%;
            font-weight: bold;
            margin: 0px;
        }
        a {
            &:hover {
                color: #000;
            }
        }
    }
    a .menu-text {
        color: white;
        cursor: pointer;
    }
    #sidebar-left {
        width: 100% - 1%;
        box-sizing: border-box;
        min-height: 0;
        background: none;
        .navigation {
            width: 100%;
            box-sizing: border-box;
            display: block;
            position: relative;
            z-index: 10;
        }
        a {
            font-family: CS ChatthaiUI, Arial, Sans-Serif, Tahoma;
        }
    }
    .SiteHeaderDropPanelFirst {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .SiteHeaderDropPanelSecond {
        width: 100%;
        position: relative;
    }
    #cart-info {
        @include flexCenter;
        margin: 0em;
        padding: 1em;
        >span {
            flex: 1;
            font-size: 2.0rem;
            +* {
                flex: 2;
                margin: 0;
            }
        }
    }
    #item-added::before {
        content: 'สั่งซื้อคอนกรีต ';
    }
    label {
        &.title {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin: 1em 1.5em;
            border-top: 1px solid $bg-header-white;
            text-align: center;
            >span {
                flex: 1;
                padding: 0.5em;
            }
            >h3 {
                flex: 2;
                padding: 0.5em;
            }
            i {
                flex: 1;
            }
        }
    }
    .watermark-logo {
        display: block;
        width: 32px;
        height: 32px;
        right: 15px;
        position: absolute;
        margin: 0 5px 5px 0;
        border: 1px solid #f06;
        bottom: 15px;
    }
    .paginate {
        font: 12px/14px "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        display: block;
        position: relative;
        clear: both;
        margin: 0 auto;
    }
    .product-long-sm,
    .bottom-section {
        background-color: $bg-header-gray;
    }
    .bottom-section {
        width: 100%;
        position: relative;
        padding: 1rem;
    }
    /*CSS Flickr style pagination*/
    div.pagination {
        padding: 3px;
        margin: 3px;
        text-align: center;
        font-size: 1.75rem;
        a {
            border: 1px solid #dedfde;
            margin-right: 3px;
            padding: 2px 6px;
            background-position: bottom;
            text-decoration: none;
            color: #0061de;
        }
        &:hover,
        &:active {
            color: $color-lightest;
        }
        span {
            &.current {
                margin-right: 3px;
                padding: 2px 6px;
                font-weight: bold;
                color: #ff0084;
            }
            &.disabled {
                margin-right: 3px;
                padding: 2px 6px;
                color: #adaaad;
            }
        }
    }
    .img-hover {
        a {
            &:hover {
                span {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100px;
                    width: 150px;
                    background-color: #ccc;
                    filter: alpha(opacity=50);
                    opacity: .5;
                    z-index: 2;
                }
            }
        }
    }
    .img-hover200 {
        a {
            &:hover {
                span {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    min-height: 100%;
                    width: 100%;
                    background-color: #ccc;
                    filter: alpha(opacity=50);
                    opacity: .5;
                    z-index: 2;
                }
            }
        }
    }
    .img-hover240 {
        a {
            &:hover {
                span {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    min-height: 100%;
                    width: 100%;
                    background-color: #ccc;
                    filter: alpha(opacity=80);
                    opacity: .8;
                    z-index: 2;
                    border-bottom: 4px solid #ededed;
                }
            }
        }
    }
    .ui-widget-content {
        font: 13px/15px 'CS ChatthaiUI', "Microsoft Sans Serif", Tahoma, Geneva, sans-serif;
        li,
        p {
            font: 1.2em/1.45em 'CS ChatthaiUI', "Microsoft Sans Serif", Tahoma, Geneva, sans-serif;
            letter-spacing: 0.4px;
        }
    }
    .nav_browser {
        // position: static;
        right: 0px;
        bottom: -15px;
        margin-right: 1px;
        margin-bottom: 10px;
        overflow: visible;
        &_subcat {
            display: none;
            position: relative;
            top: 0;
            left: 0;
            height: 100%;
            width: 260px
        }
    }
    ul.flyout_left {
        left: 10px !important;
        width: 400px;
        overflow: hidden;
    }
    .a-button-20,
    .bt-box button {
        box-shadow: inset 0px 1px 0px 0px #fbafe3;
        background: linear-gradient(to bottom, #ff5bb0 5%, #ef027d 100%);
        background-color: #ff5bb0;
        border-radius: 20px;
        border: 1px solid #ee1eb5;
        color: $color-lightest;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        text-shadow: 0px 1px 0px #c70067;
        &:hover {
            background: linear-gradient(to bottom, #ef027d 5%, #ff5bb0 100%);
            background-color: #ef027d;
        }
        &:active {
            position: relative;
            top: 1px;
        }
    }
    .a-button {
        background-color: #666;
        width: 5em;
        margin: 0 4px;
        color: $color-lightest;
        padding: 2px 4px;
        cursor: pointer;
        &:hover {
            background-color: #999;
        }
    }
    .a-button-bg {
        font-size: 0.8em;
        color: $color-lightest;
        cursor: pointer;
        padding: 2px 4px;
    }
    .a-button-bg {
        &>span:hover {
            background-color: #999;
        }
    }
    .ribbon {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
    }
    .ribbon .text,
    .ribbon .banner,
    .ribbon {
        a {
            margin: 0;
            padding: 0;
            border: 0;
            outline: 0;
            font-size: 100%;
            vertical-align: baseline;
            background: transparent;
            color: $color-lightest;
            text-decoration: none;
        }
    }
    .ribbon {
        .banner {
            transform: rotate(45deg);
            transform: rotate(45deg);
            color: $color-lightest;
            -webkit-font-smoothing: antialiased;
            display: block;
            float: right;
            position: relative;
            right: -14px;
            top: 22px;
            width: 100px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            &::after {
                content: '';
                display: block;
                height: 12px;
                position: absolute;
                width: 30px;
                transform: translate(100%, -100%) skewY(45deg) translateX(-58%);
                transform-origin: 0 center;
                right: -17px;
                background-color: #51a351;
            }
            &::before {
                content: '';
                display: block;
                height: 12px;
                position: absolute;
                width: 30px;
                transform: skewY(-45deg) translate(50%, 15px);
                transform-origin: 100% center;
                left: -45px;
                background-color: #51a351;
            }
        }
        .text {
            position: relative;
            z-index: 2;
            padding: 6px 0;
            font-size: 20px;
            font-weight: bold;
            min-height: 18px;
            line-height: 15px;
            text-align: center;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.20);
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: $primary-fonts;
            &::before {
                content: '';
                display: block;
                height: 30px;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
                background-color: #62c462;
                transform: translateX(-15%) skewX(-45deg);
            }
            &::after {
                content: '';
                display: block;
                height: 30px;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
                transform: translateX(15%) skewX(45deg);
                background-color: #62c462;
                transform: translateX(15%) skewX(45deg);
            }
        }
    }
    .ribbon-blue-mms {
        .banner {
            &::after {
                background-color: #2d5b98;
            }
            &::before {
                background-color: #2d5b98;
            }
        }
        .text {
            &::before {
                background-color: #367ab3;
            }
            &::after {
                background-color: #367ab3;
            }
        }
    }
}