@import "./variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~bootstrap/scss/mixins";
@import './mains.scss';
@import './small.scss';
@import './med.scss';
@import "~bootstrap/scss/utilities";
.active{font-weight: bold;}
.text-indent{text-indent: 2em;}
.bg-pink {
  background: linear-gradient(180deg, #ff5bb0 5%, #ef027d);
  background-color: #ff5bb0;
  border: 1px solid #ee1eb5;
  box-shadow: inset 0 1px 0 0 #fbafe3;
  text-shadow: 0 1px 0 1px #c70067;
  &:hover {
    background: linear-gradient(180deg, #ef027d 5%, #ff5bb0);
    text-shadow: 0 1px 0 1px #950045;
  }
}
$pink-color: (
    "pink":#F06,
    );
        $utilities:
        (
          "borders": (
            property: border-color,
            class: border,
            values: (
              pink: #F06
            )
          ),
            "bg-pink":(
              property: background-color,
              class:"bg-pink",
              values:(
                "default": linear-gradient(180deg, #ff5bb0 5%, #ef027d)
            )
          )
      );
      
@import "~bootstrap/scss/utilities/api";