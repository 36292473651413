@mixin minq($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin maxq($size) {
    @media only screen and (max-width: $size) {
        @content;
    }
}

@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexSpaceAround {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin flexSpaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@include maxq(899px) {
    #overlay,
    .overlay {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.9);
        transition: 1s 0.4s;
    }
    .overlay {
        width: 0;
        height: 100%;
        overflow-x: hidden;
    }
    .closebtn {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 3em;
        text-decoration: none;
    }
}

#breadcrumb,
.breadcrumb,
.breadcrumbs {
    box-sizing: border-box;
}

.pointer,
input[type=checkbox],
input[type=radio] {
    cursor: pointer;
}

input[type=checkbox] {
    +label {
        @extend .pointer;
    }
}

.mar2 {
    vertical-align: top;
    margin-left: 2em;
}

.rotate {
    transform: rotate(-180deg);
    transition: all 1s ease-out;
}

.btn-100 {
    width: 100%;
    line-height: 2;
}

span {
    &.cart-item-img {
        width: 50px;
        height: 50px;
        display: block;
    }
}

#search-submit,
.compare>input.cool,
.compare-selected>input.remove,
.bank-container,
#ui-datepicker-div,
.tablets,
.hide,
.hidden {
    display: none;
}

#progress {
    height: 1px;
    background: #fff;
    position: absolute;
    width: 0;
    top: 50%;
}

#progstat {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    font-size: 0.7em;
    letter-spacing: 3px;
    text-align: center;
    color: #fff;
}

#menu-container h3 {
    cursor: pointer;
}

// p {
//     font: 1.6rem/1.5 CS ChatThaiUI, Microsoft Sans Serif, Tahoma, Verdana, Sans Serif;
//     letter-spacing: 0.15rem;
//     margin: 2rem 0;
// }

ul {
    // list-style-position: inside;
    .flyout_left {
        left: 10px !important;
        width: 400px;
        overflow: hidden;
    }
    &.tags-list {
        li {
            &:hover {
                background-color: #F2FEFF;
            }
        }
    }
}

.nav_browse_subcat {
    display: none;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px
}

hr {
    background-color: #E8E8E8;
    border: medium none;
    color: #E8E8E8;
    height: 3px;
    margin: 0 0 10px;
    width: auto;
}

a {
    color: $a-color;
    text-decoration: none;
    &:hover {
        color: $a-hover-color;
    }
    img {
        border: none;
        max-width: 100%;
        max-height: 100%;
    }
    .LinkInvert {
        &:link,
        &:visited {
            color: #CCCCCC;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }
    }
}

.truck-title,
.truck-unit {
    background: #F2F2F2;
}

#loading {
    text-align: center;
    height: 150px;
    background: url('../images/loading.gif') no-repeat center;
}

.col-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row {
    margin-left: -5px;
    margin-right: -5px;
}

.contact {
    grid-column: 5/7;
    grid-row: 1/-1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fwb {
    font-weight: 950;
    font-size: 1.85em;
    letter-spacing: 0.5rem;
}

ul.filter-wrap,
ul.filter-selected-wrap,
ul.tags-list,
.list-none,
ul.list-none {
    list-style: none;
}

.clear {
    clear: both;
    &both {
        clear: both;
        margin: 2px 0px;
    }
}

.prod-img {
    text-align: center;
    position: relative;
}

.watermark-logo {
    display: block;
    width: 32px;
    height: 32px;
    right: 15px;
    position: absolute;
    margin: 0 5px 5px 0;
    border: 1px solid #f06;
    bottom: 15px;
}

// .paginate {
//     font: 12px/14px "Trebuchet MS", Arial, Helvetica, sans-serif;
//     text-align: center;
//     display: block;
//     height: 20px;
//     width: 500px;
//     position: relative;
//     clear: both;
//     margin: 0 auto;
// }

.hilite {
    background-color: #FFC;
}

.indent {
    text-indent: 50px;
}

.ImgFloatLeft {
    float: left;
    margin: 1px 2px;
}

.priceline {
    text-decoration: line-through;
}

.noneunderline {
    text-decoration: none;
}

.bggre {
    background: rgba(23, 91, 179, 1);
    background: linear-gradient(45deg, rgba(23, 91, 179, 1) 0%, rgba(0, 51, 153, 1) 100%);
}

.super_cat {
    width: 500px;
}

.statusMessage {
    display: none;
    margin: auto;
    width: 30em;
    height: 2em;
    padding: 1.5em;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.positioned {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.plant_logo {
    position: relative;
    display: block;
    width: 190px;
    margin: 5px 0px 5px 10px;
    padding: 2px 0px 2px 0px;
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D1F5FA;
    border-left: 1px solid #9FF;
    border-top: 1px solid #97EFF9;
}

.small {
    font: 12px "Microsoft Sans Serif", Tahoma, sans-serif;
    letter-spacing: 0.20px;
}

.contactTopRight {
    font-family: "thaisans_neueregular", Microsoft Sans Serif, Tahoma, Arial, sans-serif;
}

.center {
    display: inline-block;
    height: 10%;
    vertical-align: middle;
}

.handle {
    width: 100%;
    background: #494A4C;
    text-align: right;
    box-sizing: border-box;
    cursor: pointer;
    color: white;
    display: none;
}

.icon-nav-container {
    width: 32px;
    height: 32px;
    display: inline-block;
}

.block-container {
    display: block;
    width: 95%;
    height: auto;
    margin: 1rem auto;
}

.wrapper-box {
    position: relative;
    display: block;
    width: 100%;
    padding: 0.5em .8em;
    box-shadow: 1px 1px 1px 3px rgba(230, 230, 230, 0.2);
    margin: 1em 0;
}

.growSpace {
    flex-grow: 1;
}

.circle {
    border: 3px solid $border-dark-color;
    border-radius: 50%;
    padding: 1.25rem;
}

.mt-1 {
    margin-top: 1rem;
}

.flex {
    display: flex !important;
}

.overlay,
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.sqborder {
    border: 1px solid $bg-header-gray;
    padding: 0.5rem;
}

.mt-05 {
    margin-top: 0.5rem;
}